/* Vendor */
@import 'npm:normalize.css/normalize.css';
@import 'npm:@uppy/core/dist/style.css';
@import 'npm:@uppy/dashboard/dist/style.css';


/* App */
@import './base';
@import './ui';
@import './sandbox';
@import './controls';
@import './preview';
@import './theme';
@import './snippet';
