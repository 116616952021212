.fp-sandbox {
  color: #333b4f;
  -webkit-tap-highlight-color: transparent;
  font-size: 18px;
  font-family: "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

.fp-sandbox *,
.fp-sandbox *:after,
.fp-sandbox *:before {
  outline: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  box-sizing: inherit;
}
