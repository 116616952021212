html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

.uppy-Root {
  box-sizing: border-box;
  color: #333;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, helvetica, arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  line-height: 1;
  position: relative;
}

[dir="rtl"] .uppy-Root, .uppy-Root[dir="rtl"] {
  text-align: right;
}

.uppy-Root *, .uppy-Root :before, .uppy-Root :after {
  box-sizing: inherit;
}

.uppy-Root [hidden] {
  display: none;
}

.uppy-u-reset {
  z-index: auto;
  float: none;
  clear: none;
  min-width: 0;
  max-width: none;
  min-height: 0;
  max-height: none;
  color: inherit;
  font-weight: normal;
  font-size: inherit;
  font-variant: normal;
  letter-spacing: normal;
  white-space: normal;
  text-align: left;
  text-transform: none;
  text-indent: 0;
  text-shadow: none;
  vertical-align: baseline;
  hyphens: none;
  unicode-bidi: normal;
  empty-cells: show;
  border-collapse: separate;
  border-spacing: 0;
  outline: medium none invert;
  box-shadow: none;
  transform-origin: 50% 50% 0;
  transform-style: flat;
  backface-visibility: visible;
  visibility: visible;
  cursor: auto;
  opacity: 1;
  -webkit-appearance: none;
  background: none;
  border: none;
  border-image: ;
  border-radius: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  text-decoration: none;
  list-style: none;
  transition: none;
  display: inline;
  top: auto;
  left: auto;
  overflow: visible;
  transform: none;
}

[dir="rtl"] .uppy-u-reset {
  text-align: right;
}

.uppy-c-textInput {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 6px 8px;
  font-size: 14px;
  line-height: 1.5;
}

.uppy-size--md .uppy-c-textInput {
  padding: 8px 10px;
}

.uppy-c-textInput:focus {
  border-color: #2275d799;
  outline: none;
  box-shadow: 0 0 0 3px #2275d726;
}

[data-uppy-theme="dark"] .uppy-c-textInput {
  color: #eaeaea;
  background-color: #333;
  border-color: #333;
}

[data-uppy-theme="dark"] .uppy-c-textInput:focus {
  box-shadow: none;
  border-color: #525252;
}

.uppy-c-icon {
  max-width: 100%;
  max-height: 100%;
  fill: currentColor;
  display: inline-block;
  overflow: hidden;
}

.uppy-c-btn {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  transition-property: background-color, color;
  transition-duration: .3s;
  display: inline-block;
}

[dir="rtl"] .uppy-c-btn {
  text-align: center;
}

.uppy-c-btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.uppy-c-btn::-moz-focus-inner {
  border: 0;
}

.uppy-c-btn-primary {
  color: #fff;
  background-color: #2275d7;
  border-radius: 4px;
  padding: 10px 18px;
  font-size: 14px;
}

.uppy-c-btn-primary:hover {
  background-color: #1b5dab;
}

.uppy-c-btn-primary:focus {
  outline: none;
  box-shadow: 0 0 0 3px #2275d766;
}

.uppy-size--md .uppy-c-btn-primary {
  padding: 13px 22px;
}

[data-uppy-theme="dark"] .uppy-c-btn-primary {
  color: #eaeaea;
}

[data-uppy-theme="dark"] .uppy-c-btn-primary:focus {
  outline: none;
}

[data-uppy-theme="dark"] .uppy-c-btn-primary::-moz-focus-inner {
  border: 0;
}

[data-uppy-theme="dark"] .uppy-c-btn-primary:focus {
  box-shadow: 0 0 0 2px #aae1ffd9;
}

.uppy-c-btn-link {
  color: #525252;
  background-color: #0000;
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 1;
}

.uppy-c-btn-link:hover {
  color: #333;
}

.uppy-c-btn-link:focus {
  outline: none;
  box-shadow: 0 0 0 3px #2275d740;
}

.uppy-size--md .uppy-c-btn-link {
  padding: 13px 18px;
}

[data-uppy-theme="dark"] .uppy-c-btn-link {
  color: #eaeaea;
}

[data-uppy-theme="dark"] .uppy-c-btn-link:focus {
  outline: none;
}

[data-uppy-theme="dark"] .uppy-c-btn-link::-moz-focus-inner {
  border: 0;
}

[data-uppy-theme="dark"] .uppy-c-btn-link:focus {
  box-shadow: 0 0 0 2px #aae1ffd9;
}

[data-uppy-theme="dark"] .uppy-c-btn-link:hover {
  color: #939393;
}

.uppy-c-btn--small {
  border-radius: 2px;
  padding: 7px 16px;
  font-size: .9em;
}

.uppy-size--md .uppy-c-btn--small {
  border-radius: 2px;
  padding: 8px 10px;
}

.uppy-Informer {
  z-index: 1005;
  text-align: center;
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
}

.uppy-Informer span > div {
  margin-bottom: 6px;
}

.uppy-Informer-animated {
  z-index: -1000;
  opacity: 0;
  transition: all .3s ease-in;
  transform: translateY(350%);
}

.uppy-Informer p {
  max-width: 90%;
  color: #fff;
  background-color: #757575;
  border-radius: 18px;
  margin: 0;
  padding: 6px 15px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  display: inline-block;
}

.uppy-size--md .uppy-Informer p {
  max-width: 500px;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 1.3;
}

[data-uppy-theme="dark"] .uppy-Informer p {
  background-color: #333;
}

[dir="ltr"] .uppy-Informer p span {
  left: 3px;
}

[dir="rtl"] .uppy-Informer p span {
  right: 3px;
}

[dir="ltr"] .uppy-Informer p span {
  margin-left: -1px;
}

[dir="rtl"] .uppy-Informer p span {
  margin-right: -1px;
}

.uppy-Informer p span {
  width: 13px;
  height: 13px;
  color: #525252;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 50%;
  font-size: 10px;
  line-height: 12px;
  display: inline-block;
  position: relative;
  top: -1px;
}

.uppy-Informer p span:hover {
  cursor: help;
}

.uppy-Informer p span:after {
  word-wrap: break-word;
  line-height: 1.3;
}

.uppy-Root [aria-label][role~="tooltip"] {
  position: relative;
}

.uppy-Root [aria-label][role~="tooltip"]:before, .uppy-Root [aria-label][role~="tooltip"]:after {
  z-index: 10;
  box-sizing: border-box;
  transform-origin: top;
  backface-visibility: hidden;
  opacity: 0;
  transition: all var(--microtip-transition-duration, .18s) var(--microtip-transition-easing, ease-in-out) var(--microtip-transition-delay, 0s);
  pointer-events: none;
  will-change: transform;
  position: absolute;
  transform: translate3d(0, 0, 0);
}

.uppy-Root [aria-label][role~="tooltip"]:before {
  content: "";
  background-size: 100% !important;
}

.uppy-Root [aria-label][role~="tooltip"]:after {
  box-sizing: content-box;
  color: #fff;
  font-weight: var(--microtip-font-weight, normal);
  font-size: var(--microtip-font-size, 13px);
  white-space: nowrap;
  text-transform: var(--microtip-text-transform, none);
  content: attr(aria-label);
  background: #111111e6;
  border-radius: 4px;
  padding: .5em 1em;
}

.uppy-Root [aria-label][role~="tooltip"]:hover:before, .uppy-Root [aria-label][role~="tooltip"]:hover:after, .uppy-Root [aria-label][role~="tooltip"]:focus:before, .uppy-Root [aria-label][role~="tooltip"]:focus:after {
  opacity: 1;
  pointer-events: auto;
}

.uppy-Root [role~="tooltip"][data-microtip-position|="top"]:before {
  width: 18px;
  height: 6px;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%280%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  margin-bottom: 5px;
  bottom: 100%;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position|="top"]:after {
  margin-bottom: 11px;
  bottom: 100%;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position|="top"]:hover:before, .uppy-Root [role~="tooltip"][data-microtip-position="top"]:hover:after {
  transform: translate3d(-50%, -5px, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="top-left"]:after {
  bottom: 100%;
  transform: translate3d(calc(16px - 100%), 0, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="top-left"]:hover:after {
  transform: translate3d(calc(16px - 100%), -5px, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="top-right"]:after {
  bottom: 100%;
  transform: translate3d(-16px, 0, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="top-right"]:hover:after {
  transform: translate3d(-16px, -5px, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]:before {
  width: 18px;
  height: 6px;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%28180%2018%206%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  margin-top: 5px;
  margin-bottom: 0;
  top: 100%;
  bottom: auto;
  left: 50%;
  transform: translate3d(-50%, -10px, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]:after {
  margin-top: 11px;
  top: 100%;
  left: 50%;
  transform: translate3d(-50%, -10px, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]:hover:before, .uppy-Root [role~="tooltip"][data-microtip-position="bottom"]:hover:after {
  transform: translate3d(-50%, 0, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="bottom-left"]:after {
  top: 100%;
  transform: translate3d(calc(16px - 100%), -10px, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="bottom-left"]:hover:after {
  transform: translate3d(calc(16px - 100%), 0, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="bottom-right"]:after {
  top: 100%;
  transform: translate3d(-16px, -10px, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="bottom-right"]:hover:after {
  transform: translate3d(-16px, 0, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="left"]:before, .uppy-Root [role~="tooltip"][data-microtip-position="left"]:after {
  inset: 50% 100% auto auto;
  transform: translate3d(10px, -50%, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="left"]:before {
  width: 6px;
  height: 18px;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%28-90%2018%2018%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  margin-bottom: 0;
  margin-right: 5px;
}

.uppy-Root [role~="tooltip"][data-microtip-position="left"]:after {
  margin-right: 11px;
}

.uppy-Root [role~="tooltip"][data-microtip-position="left"]:hover:before, .uppy-Root [role~="tooltip"][data-microtip-position="left"]:hover:after {
  transform: translate3d(0, -50%, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="right"]:before, .uppy-Root [role~="tooltip"][data-microtip-position="right"]:after {
  top: 50%;
  bottom: auto;
  left: 100%;
  transform: translate3d(-10px, -50%, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="right"]:before {
  width: 6px;
  height: 18px;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%2890%206%206%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  margin-bottom: 0;
  margin-left: 5px;
}

.uppy-Root [role~="tooltip"][data-microtip-position="right"]:after {
  margin-left: 11px;
}

.uppy-Root [role~="tooltip"][data-microtip-position="right"]:hover:before, .uppy-Root [role~="tooltip"][data-microtip-position="right"]:hover:after {
  transform: translate3d(0, -50%, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-size="small"]:after {
  width: 80px;
  white-space: initial;
}

.uppy-Root [role~="tooltip"][data-microtip-size="medium"]:after {
  width: 150px;
  white-space: initial;
}

.uppy-Root [role~="tooltip"][data-microtip-size="large"]:after {
  width: 260px;
  white-space: initial;
}

.uppy-StatusBar {
  z-index: 1001;
  height: 46px;
  color: #fff;
  background-color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 40px;
  transition: height .2s;
  display: flex;
  position: relative;
}

[data-uppy-theme="dark"] .uppy-StatusBar {
  background-color: #1f1f1f;
}

.uppy-StatusBar:before {
  width: 100%;
  height: 2px;
  content: "";
  background-color: #eaeaea;
  position: absolute;
  inset: 0;
}

[data-uppy-theme="dark"] .uppy-StatusBar:before {
  background-color: #757575;
}

.uppy-StatusBar[aria-hidden="true"] {
  height: 0;
  overflow-y: hidden;
}

.uppy-StatusBar.is-complete .uppy-StatusBar-progress {
  background-color: #1bb240;
}

.uppy-StatusBar.is-error .uppy-StatusBar-progress {
  background-color: #e32437;
}

.uppy-StatusBar.is-complete .uppy-StatusBar-statusIndicator {
  color: #1bb240;
}

.uppy-StatusBar.is-error .uppy-StatusBar-statusIndicator {
  color: #e32437;
}

.uppy-StatusBar:not([aria-hidden="true"]).is-waiting {
  height: 65px;
  background-color: #fff;
  border-top: 1px solid #eaeaea;
}

[data-uppy-theme="dark"] .uppy-StatusBar:not([aria-hidden="true"]).is-waiting {
  background-color: #1f1f1f;
  border-top: 1px solid #333;
}

.uppy-StatusBar-progress {
  z-index: 1001;
  height: 2px;
  background-color: #2275d7;
  transition: background-color, width .3s ease-out;
  position: absolute;
}

.uppy-StatusBar-progress.is-indeterminate {
  background-image: linear-gradient(45deg, #0000004d 25%, #0000 25% 50%, #0000004d 50% 75%, #0000 75%, #0000);
  background-size: 64px 64px;
  animation: uppy-StatusBar-ProgressStripes 1s linear infinite;
}

@keyframes uppy-StatusBar-ProgressStripes {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 64px 0;
  }
}

.uppy-StatusBar.is-preprocessing .uppy-StatusBar-progress, .uppy-StatusBar.is-postprocessing .uppy-StatusBar-progress {
  background-color: #f6a623;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-progress {
  display: none;
}

[dir="ltr"] .uppy-StatusBar-content {
  padding-left: 10px;
}

[dir="rtl"] .uppy-StatusBar-content {
  padding-right: 10px;
}

.uppy-StatusBar-content {
  z-index: 1002;
  height: 100%;
  color: #333;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  display: flex;
  position: relative;
}

[dir="ltr"] .uppy-size--md .uppy-StatusBar-content {
  padding-left: 15px;
}

[dir="rtl"] .uppy-size--md .uppy-StatusBar-content {
  padding-right: 15px;
}

[data-uppy-theme="dark"] .uppy-StatusBar-content {
  color: #eaeaea;
}

[dir="ltr"] .uppy-StatusBar-status {
  padding-right: .3em;
}

[dir="rtl"] .uppy-StatusBar-status {
  padding-left: .3em;
}

.uppy-StatusBar-status {
  flex-direction: column;
  justify-content: center;
  font-weight: normal;
  line-height: 1.4;
  display: flex;
}

.uppy-StatusBar-statusPrimary {
  font-weight: 500;
  line-height: 1;
  display: flex;
}

.uppy-StatusBar-statusPrimary button.uppy-StatusBar-details {
  margin-left: 5px;
}

[data-uppy-theme="dark"] .uppy-StatusBar-statusPrimary {
  color: #eaeaea;
}

.uppy-StatusBar-statusSecondary {
  color: #757575;
  white-space: nowrap;
  margin-top: 1px;
  font-size: 11px;
  line-height: 1.2;
  display: inline-block;
}

[data-uppy-theme="dark"] .uppy-StatusBar-statusSecondary {
  color: #bbb;
}

[dir="ltr"] .uppy-StatusBar-statusSecondaryHint {
  margin-right: 5px;
}

[dir="rtl"] .uppy-StatusBar-statusSecondaryHint {
  margin-left: 5px;
}

.uppy-StatusBar-statusSecondaryHint {
  vertical-align: middle;
  line-height: 1;
  display: inline-block;
}

[dir="ltr"] .uppy-size--md .uppy-StatusBar-statusSecondaryHint {
  margin-right: 8px;
}

[dir="rtl"] .uppy-size--md .uppy-StatusBar-statusSecondaryHint {
  margin-left: 8px;
}

[dir="ltr"] .uppy-StatusBar-statusIndicator {
  margin-right: 7px;
}

[dir="rtl"] .uppy-StatusBar-statusIndicator {
  margin-left: 7px;
}

.uppy-StatusBar-statusIndicator {
  color: #525252;
  position: relative;
  top: 1px;
}

.uppy-StatusBar-statusIndicator svg {
  vertical-align: text-bottom;
}

[dir="ltr"] .uppy-StatusBar-actions {
  right: 10px;
}

[dir="rtl"] .uppy-StatusBar-actions {
  left: 10px;
}

.uppy-StatusBar-actions {
  z-index: 1004;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actions {
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  padding: 0 15px;
  position: static;
}

[data-uppy-theme="dark"] .uppy-StatusBar.is-waiting .uppy-StatusBar-actions {
  background-color: #1f1f1f;
}

.uppy-StatusBar:not([aria-hidden="true"]).is-waiting.has-ghosts {
  height: 90px;
  flex-direction: column;
}

.uppy-size--md .uppy-StatusBar:not([aria-hidden="true"]).is-waiting.has-ghosts {
  height: 65px;
  flex-direction: row;
}

.uppy-StatusBar:not([aria-hidden="true"]).is-waiting.has-ghosts .uppy-StatusBar-actions {
  flex-direction: column;
  justify-content: center;
}

.uppy-size--md .uppy-StatusBar:not([aria-hidden="true"]).is-waiting.has-ghosts .uppy-StatusBar-actions {
  justify-content: initial;
  flex-direction: row;
}

.uppy-StatusBar-actionCircleBtn {
  cursor: pointer;
  opacity: .9;
  margin: 3px;
  line-height: 1;
}

.uppy-StatusBar-actionCircleBtn:focus {
  outline: none;
}

.uppy-StatusBar-actionCircleBtn::-moz-focus-inner {
  border: 0;
}

.uppy-StatusBar-actionCircleBtn:focus {
  box-shadow: 0 0 0 3px #2275d780;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionCircleBtn:focus {
  outline: none;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionCircleBtn::-moz-focus-inner {
  border: 0;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionCircleBtn:focus {
  box-shadow: 0 0 0 2px #aae1ffd9;
}

.uppy-StatusBar-actionCircleBtn:hover {
  opacity: 1;
}

.uppy-StatusBar-actionCircleBtn:focus {
  border-radius: 50%;
}

.uppy-StatusBar-actionCircleBtn svg {
  vertical-align: bottom;
}

.uppy-StatusBar-actionBtn {
  color: #2275d7;
  font-size: 10px;
  line-height: inherit;
  vertical-align: middle;
  display: inline-block;
}

.uppy-size--md .uppy-StatusBar-actionBtn {
  font-size: 11px;
}

.uppy-StatusBar-actionBtn--disabled {
  opacity: .4;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionBtn--disabled {
  opacity: .7;
}

[dir="ltr"] .uppy-StatusBar-actionBtn--retry {
  margin-right: 6px;
}

[dir="rtl"] .uppy-StatusBar-actionBtn--retry {
  margin-left: 6px;
}

.uppy-StatusBar-actionBtn--retry {
  height: 16px;
  color: #fff;
  background-color: #ff4b23;
  border-radius: 8px;
  padding: 1px 6px 3px 18px;
  line-height: 1;
  position: relative;
}

.uppy-StatusBar-actionBtn--retry:focus {
  outline: none;
}

.uppy-StatusBar-actionBtn--retry::-moz-focus-inner {
  border: 0;
}

.uppy-StatusBar-actionBtn--retry:focus {
  box-shadow: 0 0 0 3px #2275d780;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionBtn--retry:focus {
  outline: none;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionBtn--retry::-moz-focus-inner {
  border: 0;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionBtn--retry:focus {
  box-shadow: 0 0 0 2px #aae1ffd9;
}

.uppy-StatusBar-actionBtn--retry:hover {
  background-color: #f92d00;
}

[dir="ltr"] .uppy-StatusBar-actionBtn--retry svg {
  left: 6px;
}

[dir="rtl"] .uppy-StatusBar-actionBtn--retry svg {
  right: 6px;
}

.uppy-StatusBar-actionBtn--retry svg {
  position: absolute;
  top: 3px;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  width: 100%;
  color: #fff;
  background-color: #1bb240;
  padding: 15px 10px;
  font-size: 14px;
  line-height: 1;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload:hover {
  background-color: #189c38;
}

[data-uppy-theme="dark"] .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  background-color: #1c8b37;
}

[data-uppy-theme="dark"] .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload:hover {
  background-color: #18762f;
}

.uppy-size--md .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  width: auto;
  padding: 13px 22px;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload.uppy-StatusBar-actionBtn--disabled:hover {
  cursor: not-allowed;
  background-color: #1bb240;
}

[data-uppy-theme="dark"] .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload.uppy-StatusBar-actionBtn--disabled:hover {
  background-color: #1c8b37;
}

.uppy-StatusBar:not(.is-waiting) .uppy-StatusBar-actionBtn--upload {
  color: #2275d7;
  background-color: #0000;
}

[dir="ltr"] .uppy-StatusBar-actionBtn--uploadNewlyAdded {
  padding-right: 3px;
}

[dir="rtl"] .uppy-StatusBar-actionBtn--uploadNewlyAdded, [dir="ltr"] .uppy-StatusBar-actionBtn--uploadNewlyAdded {
  padding-left: 3px;
}

[dir="rtl"] .uppy-StatusBar-actionBtn--uploadNewlyAdded {
  padding-right: 3px;
}

.uppy-StatusBar-actionBtn--uploadNewlyAdded {
  border-radius: 3px;
  padding-bottom: 1px;
}

.uppy-StatusBar-actionBtn--uploadNewlyAdded:focus {
  outline: none;
}

.uppy-StatusBar-actionBtn--uploadNewlyAdded::-moz-focus-inner {
  border: 0;
}

.uppy-StatusBar-actionBtn--uploadNewlyAdded:focus {
  box-shadow: 0 0 0 3px #2275d780;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionBtn--uploadNewlyAdded:focus {
  outline: none;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionBtn--uploadNewlyAdded::-moz-focus-inner {
  border: 0;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionBtn--uploadNewlyAdded:focus {
  box-shadow: 0 0 0 2px #aae1ffd9;
}

.uppy-StatusBar-actionBtn--done {
  border-radius: 3px;
  padding: 7px 8px;
  line-height: 1;
}

.uppy-StatusBar-actionBtn--done:focus {
  outline: none;
}

.uppy-StatusBar-actionBtn--done::-moz-focus-inner {
  border: 0;
}

.uppy-StatusBar-actionBtn--done:hover {
  color: #1b5dab;
}

.uppy-StatusBar-actionBtn--done:focus {
  background-color: #eceef2;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionBtn--done:focus {
  background-color: #333;
}

[data-uppy-theme="dark"] .uppy-StatusBar-actionBtn--done {
  color: #02baf2;
}

.uppy-size--md .uppy-StatusBar-actionBtn--done {
  font-size: 14px;
}

.uppy-StatusBar-serviceMsg {
  color: #000;
  padding-left: 10px;
  font-size: 11px;
  line-height: 1.1;
}

.uppy-size--md .uppy-StatusBar-serviceMsg {
  padding-left: 15px;
  font-size: 14px;
}

[data-uppy-theme="dark"] .uppy-StatusBar-serviceMsg {
  color: #eaeaea;
}

.uppy-StatusBar-serviceMsg-ghostsIcon {
  width: 10px;
  vertical-align: text-bottom;
  opacity: .5;
  position: relative;
  top: 2px;
  left: 6px;
}

.uppy-size--md .uppy-StatusBar-serviceMsg-ghostsIcon {
  width: 15px;
  top: 1px;
  left: 10px;
}

[dir="ltr"] .uppy-StatusBar-details {
  left: 2px;
}

[dir="rtl"] .uppy-StatusBar-details {
  right: 2px;
}

.uppy-StatusBar-details {
  width: 13px;
  height: 13px;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: help;
  appearance: none;
  background-color: #939393;
  border-radius: 50%;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  display: inline-block;
  position: relative;
  top: 0;
}

.uppy-StatusBar-details:after {
  word-wrap: break-word;
  line-height: 1.3;
}

[dir="ltr"] .uppy-StatusBar-spinner {
  margin-right: 10px;
}

[dir="rtl"] .uppy-StatusBar-spinner {
  margin-left: 10px;
}

.uppy-StatusBar-spinner {
  fill: #2275d7;
  animation-name: uppy-StatusBar-spinnerAnimation;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.uppy-StatusBar.is-preprocessing .uppy-StatusBar-spinner, .uppy-StatusBar.is-postprocessing .uppy-StatusBar-spinner {
  fill: #f6a623;
}

@keyframes uppy-StatusBar-spinnerAnimation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.uppy-ProviderBrowser-viewType--grid ul.uppy-ProviderBrowser-list, .uppy-ProviderBrowser-viewType--unsplash ul.uppy-ProviderBrowser-list {
  flex-flow: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 6px;
  display: flex;
}

.uppy-ProviderBrowser-viewType--grid ul.uppy-ProviderBrowser-list:after, .uppy-ProviderBrowser-viewType--unsplash ul.uppy-ProviderBrowser-list:after {
  content: "";
  flex: auto;
}

.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem, .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem {
  width: 50%;
  margin: 0;
  position: relative;
}

.uppy-size--md .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem, .uppy-size--md .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem {
  width: 33.3333%;
}

.uppy-size--lg .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem, .uppy-size--lg .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem {
  width: 25%;
}

.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem:before, .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem:before {
  content: "";
  padding-top: 100%;
  display: block;
}

.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--selected img, .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--selected svg, .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--selected img, .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--selected svg {
  opacity: .85;
}

.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--disabled, .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--disabled {
  opacity: .5;
}

.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview .uppy-ProviderBrowserItem-inner, .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--noPreview .uppy-ProviderBrowserItem-inner {
  background-color: #93939333;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview .uppy-ProviderBrowserItem-inner, [data-uppy-theme="dark"] .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--noPreview .uppy-ProviderBrowserItem-inner {
  background-color: #eaeaea33;
}

.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview svg, .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--noPreview svg {
  width: 30%;
  height: 30%;
  fill: #000000b3;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview svg, [data-uppy-theme="dark"] .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--noPreview svg {
  fill: #fffc;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner {
  width: calc(100% - 14px);
  height: calc(100% - 14px);
  text-align: center;
  border-radius: 4px;
  position: absolute;
  inset: 7px;
  overflow: hidden;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner .uppy.uppy-ProviderBrowserItem-inner-relative, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner .uppy.uppy-ProviderBrowserItem-inner-relative {
  position: relative;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner .uppy-ProviderBrowserItem-author, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner .uppy-ProviderBrowserItem-author {
  width: 100%;
  color: #fff;
  background: #0000004d;
  margin: 0;
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner .uppy-ProviderBrowserItem-author:hover, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner .uppy-ProviderBrowserItem-author:hover {
  background: #0006;
  text-decoration: underline;
}

@media (hover: none) {
  .uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner .uppy-ProviderBrowserItem-author, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner .uppy-ProviderBrowserItem-author {
    display: block;
  }
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner, [data-uppy-theme="dark"] .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner {
  box-shadow: 0 0 0 3px #aae1ffb3;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner img, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox {
  z-index: 1002;
  width: 26px;
  height: 26px;
  opacity: 0;
  background-color: #2275d7;
  border-radius: 50%;
  position: absolute;
  top: 16px;
  right: 16px;
}

[dir="ltr"] .uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox:after, [dir="ltr"] .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox:after {
  left: 7px;
}

[dir="rtl"] .uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox:after, [dir="rtl"] .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox:after {
  right: 7px;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox:after, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox:after {
  width: 12px;
  height: 7px;
  top: 8px;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--is-checked, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--is-checked {
  opacity: 1;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--grid:hover + label .uppy-ProviderBrowserItem-author, .uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--grid:focus + label .uppy-ProviderBrowserItem-author, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--grid:hover + label .uppy-ProviderBrowserItem-author, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--grid:focus + label .uppy-ProviderBrowserItem-author {
  display: block;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--grid:focus + label, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--grid:focus + label {
  box-shadow: 0 0 0 3px #2275d780;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--grid:focus + label:focus, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--grid:focus + label:focus {
  outline: none;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--grid:focus + label::-moz-focus-inner, .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--grid:focus + label::-moz-focus-inner {
  border: 0;
}

.uppy-ProviderBrowser-viewType--list {
  background-color: #fff;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-viewType--list {
  background-color: #1f1f1f;
}

.uppy-ProviderBrowser-viewType--list li.uppy-ProviderBrowserItem {
  align-items: center;
  margin: 0;
  padding: 7px 15px;
  display: flex;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-viewType--list li.uppy-ProviderBrowserItem {
  color: #eaeaea;
}

.uppy-ProviderBrowser-viewType--list li.uppy-ProviderBrowserItem--disabled {
  opacity: .6;
}

[dir="ltr"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox {
  margin-right: 15px;
}

[dir="rtl"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox {
  margin-left: 15px;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox {
  width: 17px;
  height: 17px;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 3px;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox:focus {
  border: 1px solid #2275d7;
  outline: none;
  box-shadow: 0 0 0 3px #2275d740;
}

[dir="ltr"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox:after {
  left: 3px;
}

[dir="rtl"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox:after {
  right: 3px;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox:after {
  width: 9px;
  height: 5px;
  opacity: 0;
  top: 4px;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox:focus {
  border-color: #02baf2b3;
  box-shadow: 0 0 0 3px #02baf233;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox--is-checked {
  background-color: #2275d7;
  border-color: #2275d7;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox--is-checked:after {
  opacity: 1;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner {
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  padding: 2px;
  display: flex;
  overflow: hidden;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner:focus {
  outline: none;
  text-decoration: underline;
}

[dir="ltr"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner img, [dir="ltr"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner svg {
  margin-right: 8px;
}

[dir="rtl"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner img, [dir="rtl"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner svg {
  margin-left: 8px;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner img, .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner svg {
  max-width: 20px;
  max-height: 20px;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner span {
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.2;
  overflow: hidden;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem--disabled .uppy-ProviderBrowserItem-inner {
  cursor: default;
}

[dir="ltr"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-iconWrap {
  margin-right: 7px;
}

[dir="rtl"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-iconWrap {
  margin-left: 7px;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-iconWrap {
  width: 20px;
}

.uppy-ProviderBrowserItem-checkbox {
  cursor: pointer;
  flex-shrink: 0;
  position: relative;
}

.uppy-ProviderBrowserItem-checkbox:disabled {
  cursor: default;
}

.uppy-ProviderBrowserItem-checkbox:after {
  cursor: pointer;
  content: "";
  border-bottom: 2px solid #eaeaea;
  border-left: 2px solid #eaeaea;
  position: absolute;
  transform: rotate(-45deg);
}

.uppy-ProviderBrowserItem-checkbox:disabled:after {
  cursor: default;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowserItem-checkbox {
  background-color: #1f1f1f;
  border-color: #939393;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowserItem-checkbox--is-checked {
  background-color: #333;
}

.uppy-SearchProvider {
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

[data-uppy-theme="dark"] .uppy-SearchProvider {
  background-color: #1f1f1f;
}

.uppy-SearchProvider-input {
  width: 90%;
  max-width: 650px;
  margin-bottom: 15px;
}

.uppy-size--md .uppy-SearchProvider-input {
  margin-bottom: 20px;
}

.uppy-SearchProvider-searchButton {
  padding: 13px 25px;
}

.uppy-size--md .uppy-SearchProvider-searchButton {
  padding: 13px 30px;
}

.uppy-DashboardContent-panelBody {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

[data-uppy-theme="dark"] .uppy-DashboardContent-panelBody {
  background-color: #1f1f1f;
}

.uppy-Provider-auth, .uppy-Provider-error, .uppy-Provider-loading, .uppy-Provider-empty {
  color: #939393;
  flex-flow: column wrap;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uppy-Provider-empty {
  color: #939393;
}

.uppy-Provider-authIcon svg {
  width: 100px;
  height: 75px;
  margin-bottom: 15px;
}

.uppy-Provider-authTitle {
  max-width: 500px;
  color: #757575;
  text-align: center;
  margin-bottom: 30px;
  padding: 0 15px;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.4;
}

.uppy-size--md .uppy-Provider-authTitle {
  font-size: 20px;
}

[data-uppy-theme="dark"] .uppy-Provider-authTitle {
  color: #cfcfcf;
}

.uppy-Provider-btn-google {
  background: #4285f4;
  align-items: center;
  display: flex;
  padding: 8px 12px !important;
}

.uppy-Provider-btn-google:hover {
  background-color: #1266f1;
}

.uppy-Provider-btn-google:focus {
  outline: none;
  box-shadow: 0 0 0 3px #4285f466;
}

.uppy-Provider-btn-google svg {
  margin-right: 8px;
}

[dir="ltr"] .uppy-Provider-breadcrumbs {
  text-align: left;
}

[dir="rtl"] .uppy-Provider-breadcrumbs {
  text-align: right;
}

.uppy-Provider-breadcrumbs {
  color: #525252;
  flex: 1;
  margin-bottom: 10px;
  font-size: 12px;
}

.uppy-size--md .uppy-Provider-breadcrumbs {
  margin-bottom: 0;
}

[data-uppy-theme="dark"] .uppy-Provider-breadcrumbs {
  color: #eaeaea;
}

[dir="ltr"] .uppy-Provider-breadcrumbsIcon {
  margin-right: 4px;
}

[dir="rtl"] .uppy-Provider-breadcrumbsIcon {
  margin-left: 4px;
}

.uppy-Provider-breadcrumbsIcon {
  color: #525252;
  vertical-align: middle;
  line-height: 1;
  display: inline-block;
}

.uppy-Provider-breadcrumbsIcon svg {
  width: 13px;
  height: 13px;
  fill: #525252;
}

.uppy-Provider-breadcrumbs button {
  line-height: inherit;
  border-radius: 3px;
  padding: 4px;
  display: inline-block;
}

.uppy-Provider-breadcrumbs button:focus {
  outline: none;
}

.uppy-Provider-breadcrumbs button::-moz-focus-inner {
  border: 0;
}

.uppy-Provider-breadcrumbs button:hover {
  color: #1b5dab;
}

.uppy-Provider-breadcrumbs button:focus {
  background-color: #eceef2;
}

[data-uppy-theme="dark"] .uppy-Provider-breadcrumbs button:focus {
  background-color: #333;
}

.uppy-Provider-breadcrumbs button:not(:last-of-type) {
  text-decoration: underline;
}

.uppy-Provider-breadcrumbs button:last-of-type {
  color: #333;
  cursor: normal;
  pointer-events: none;
  font-weight: 500;
}

.uppy-Provider-breadcrumbs button:hover {
  cursor: pointer;
}

[data-uppy-theme="dark"] .uppy-Provider-breadcrumbs button {
  color: #eaeaea;
}

.uppy-ProviderBrowser {
  height: 100%;
  flex-direction: column;
  flex: 1;
  font-size: 14px;
  font-weight: 400;
  display: flex;
}

.uppy-ProviderBrowser-user {
  color: #333;
  margin: 0 8px 0 0;
  font-weight: 500;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-user {
  color: #eaeaea;
}

[dir="ltr"] .uppy-ProviderBrowser-user:after {
  left: 4px;
}

[dir="rtl"] .uppy-ProviderBrowser-user:after {
  right: 4px;
}

.uppy-ProviderBrowser-user:after {
  color: #939393;
  content: "·";
  font-weight: normal;
  position: relative;
}

.uppy-ProviderBrowser-header {
  z-index: 1001;
  border-bottom: 1px solid #eaeaea;
  position: relative;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-header {
  border-bottom: 1px solid #333;
}

.uppy-ProviderBrowser-headerBar {
  z-index: 1001;
  color: #757575;
  background-color: #fafafa;
  padding: 7px 15px;
  font-size: 12px;
  line-height: 1.4;
}

.uppy-size--md .uppy-ProviderBrowser-headerBar {
  align-items: center;
  display: flex;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-headerBar {
  background-color: #1f1f1f;
}

.uppy-ProviderBrowser-headerBar--simple {
  text-align: center;
  justify-content: center;
  display: block;
}

.uppy-ProviderBrowser-headerBar--simple .uppy-Provider-breadcrumbsWrap {
  vertical-align: middle;
  flex: none;
  display: inline-block;
}

.uppy-ProviderBrowser-filter {
  width: 100%;
  height: 30px;
  background-color: #fff;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  position: relative;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-filter {
  background-color: #1f1f1f;
}

[dir="ltr"] .uppy-ProviderBrowser-filterIcon {
  left: 16px;
}

[dir="rtl"] .uppy-ProviderBrowser-filterIcon {
  right: 16px;
}

.uppy-ProviderBrowser-filterIcon {
  z-index: 1002;
  width: 12px;
  height: 12px;
  color: #bbb;
  position: absolute;
}

[dir="ltr"] .uppy-ProviderBrowser-filterInput {
  padding-left: 27px;
}

[dir="rtl"] .uppy-ProviderBrowser-filterInput {
  padding-right: 27px;
}

.uppy-ProviderBrowser-filterInput {
  z-index: 1001;
  width: 100%;
  height: 30px;
  background-color: #0000;
  border: 0;
  border-radius: 4px;
  outline: 0;
  margin: 0 8px;
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, helvetica, arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 12px;
  line-height: 1.4;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-filterInput {
  color: #eaeaea;
  background-color: #1f1f1f;
}

.uppy-ProviderBrowser-filterInput:focus {
  background-color: #f4f4f4;
  outline: 0;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-filterInput:focus {
  background-color: #333;
}

.uppy-ProviderBrowser-filterInput::placeholder {
  color: #939393;
  opacity: 1;
}

.uppy-ProviderBrowser-search {
  width: 100%;
  height: 30px;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 2px;
  display: flex;
  position: relative;
}

[dir="ltr"] .uppy-ProviderBrowser-searchInput {
  padding-left: 30px;
}

[dir="rtl"] .uppy-ProviderBrowser-searchInput {
  padding-right: 30px;
}

.uppy-ProviderBrowser-searchInput {
  z-index: 1001;
  width: 100%;
  height: 30px;
  color: #333;
  background-color: #eaeaea;
  border: 0;
  border-radius: 4px;
  outline: 0;
  font-family: -apple-system, blinkmacsystemfont, Segoe UI, helvetica, arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 13px;
  line-height: 1.4;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-searchInput {
  color: #eaeaea;
  background-color: #1f1f1f;
}

.uppy-ProviderBrowser-searchInput:focus {
  background-color: #cfcfcf;
  outline: 0;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-searchInput:focus {
  background-color: #333;
}

[dir="ltr"] .uppy-ProviderBrowser-searchIcon {
  left: 10px;
}

[dir="rtl"] .uppy-ProviderBrowser-searchIcon {
  right: 10px;
}

.uppy-ProviderBrowser-searchIcon {
  z-index: 1002;
  width: 12px;
  height: 12px;
  color: #757575;
  position: absolute;
}

.uppy-ProviderBrowser-searchInput::placeholder {
  color: #939393;
  opacity: 1;
}

.uppy-ProviderBrowser-userLogout {
  color: #2275d7;
  line-height: inherit;
  cursor: pointer;
  border-radius: 3px;
  padding: 4px;
}

.uppy-ProviderBrowser-userLogout:focus {
  outline: none;
}

.uppy-ProviderBrowser-userLogout::-moz-focus-inner {
  border: 0;
}

.uppy-ProviderBrowser-userLogout:hover {
  color: #1b5dab;
}

.uppy-ProviderBrowser-userLogout:focus {
  background-color: #eceef2;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-userLogout:focus {
  background-color: #333;
}

.uppy-ProviderBrowser-userLogout:hover {
  text-decoration: underline;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-userLogout {
  color: #eaeaea;
}

.uppy-ProviderBrowser-body {
  flex: 1;
  position: relative;
}

.uppy-ProviderBrowser-list {
  width: 100%;
  height: 100%;
  border-spacing: 0;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  flex: 1;
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  position: absolute;
  inset: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-list {
  background-color: #1f1f1f;
}

.uppy-ProviderBrowser-list:focus {
  outline: none;
}

.uppy-ProviderBrowserItem-inner {
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
}

.uppy-ProviderBrowser-footer {
  height: 65px;
  background-color: #fff;
  border-top: 1px solid #eaeaea;
  align-items: center;
  padding: 0 15px;
  display: flex;
}

[dir="ltr"] .uppy-ProviderBrowser-footer button {
  margin-right: 8px;
}

[dir="rtl"] .uppy-ProviderBrowser-footer button {
  margin-left: 8px;
}

[data-uppy-theme="dark"] .uppy-ProviderBrowser-footer {
  background-color: #1f1f1f;
  border-top: 1px solid #333;
}

.uppy-Dashboard-Item-previewInnerWrap {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 2px #0006;
}

.uppy-size--md .uppy-Dashboard-Item-previewInnerWrap {
  box-shadow: 0 1px 2px #00000026;
}

.uppy-Dashboard-Item-previewInnerWrap:after {
  z-index: 1001;
  content: "";
  background-color: #000000a6;
  display: none;
  position: absolute;
  inset: 0;
}

.uppy-Dashboard-Item-previewLink {
  z-index: 1002;
  position: absolute;
  inset: 0;
}

.uppy-Dashboard-Item-previewLink:focus {
  box-shadow: inset 0 0 0 3px #76abe9;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-previewLink:focus {
  box-shadow: inset 0 0 0 3px #016c8d;
}

.uppy-Dashboard-Item-preview img.uppy-Dashboard-Item-previewImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
  transform: translateZ(0);
}

.uppy-Dashboard-Item-progress {
  z-index: 1002;
  width: 120px;
  color: #fff;
  text-align: center;
  transition: all .35 ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.uppy-Dashboard-Item-progressIndicator {
  width: 38px;
  height: 38px;
  opacity: .9;
  display: inline-block;
}

.uppy-size--md .uppy-Dashboard-Item-progressIndicator {
  width: 55px;
  height: 55px;
}

button.uppy-Dashboard-Item-progressIndicator {
  cursor: pointer;
}

button.uppy-Dashboard-Item-progressIndicator:focus {
  outline: none;
}

button.uppy-Dashboard-Item-progressIndicator::-moz-focus-inner {
  border: 0;
}

button.uppy-Dashboard-Item-progressIndicator:focus .uppy-Dashboard-Item-progressIcon--bg, button.uppy-Dashboard-Item-progressIndicator:focus .uppy-Dashboard-Item-progressIcon--retry {
  fill: #76abe9;
}

.uppy-Dashboard-Item-progressIcon--circle {
  width: 100%;
  height: 100%;
}

.uppy-Dashboard-Item-progressIcon--bg {
  stroke: #fff6;
}

.uppy-Dashboard-Item-progressIcon--progress {
  stroke: #fff;
  transition: stroke-dashoffset .5s ease-out;
}

.uppy-Dashboard-Item-progressIcon--play {
  fill: #fff;
  stroke: #fff;
  transition: all .2s;
}

.uppy-Dashboard-Item-progressIcon--cancel {
  fill: #fff;
  transition: all .2s;
}

.uppy-Dashboard-Item-progressIcon--pause {
  fill: #fff;
  stroke: #fff;
  transition: all .2s;
}

.uppy-Dashboard-Item-progressIcon--check {
  fill: #fff;
  transition: all .2s;
}

.uppy-Dashboard-Item-progressIcon--retry {
  fill: #fff;
}

[dir="ltr"] .uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progress {
  right: -8px;
}

[dir="rtl"] .uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progress {
  left: -8px;
}

[dir="ltr"] .uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progress {
  left: initial;
}

[dir="rtl"] .uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progress {
  right: initial;
}

.uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progress {
  width: auto;
  transform: initial;
  top: -9px;
}

.uppy-Dashboard-Item.is-error .uppy-Dashboard-Item-progressIndicator {
  width: 18px;
  height: 18px;
}

.uppy-size--md .uppy-Dashboard-Item.is-error .uppy-Dashboard-Item-progressIndicator {
  width: 28px;
  height: 28px;
}

.uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progressIndicator {
  width: 18px;
  height: 18px;
  opacity: 1;
}

.uppy-size--md .uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progressIndicator {
  width: 22px;
  height: 22px;
}

.uppy-Dashboard-Item.is-processing .uppy-Dashboard-Item-progress {
  opacity: 0;
}

[dir="ltr"] .uppy-Dashboard-Item-fileInfo {
  padding-right: 5px;
}

[dir="rtl"] .uppy-Dashboard-Item-fileInfo {
  padding-left: 5px;
}

.uppy-Dashboard-Item-name {
  word-wrap: anywhere;
  word-break: break-all;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-name {
  color: #eaeaea;
}

.uppy-Dashboard-Item-fileName {
  align-items: baseline;
  display: flex;
}

.uppy-Dashboard-Item-fileName button {
  margin-left: 5px;
}

.uppy-Dashboard-Item-author {
  color: #757575;
  vertical-align: bottom;
  margin-bottom: 5px;
  font-size: 11px;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

.uppy-Dashboard-Item-author a {
  color: #757575;
}

.uppy-Dashboard-Item-status {
  color: #757575;
  font-size: 11px;
  font-weight: normal;
  line-height: 1;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-status {
  color: #bbb;
}

.uppy-Dashboard-Item-statusSize {
  text-transform: uppercase;
  vertical-align: bottom;
  margin-bottom: 5px;
  display: inline-block;
}

.uppy-Dashboard-Item-reSelect {
  color: #2275d7;
  font-weight: 600;
  font-size: inherit;
  font-family: inherit;
}

.uppy-Dashboard-Item-errorMessage {
  color: #a51523;
  background-color: #fdeff1;
  padding: 5px 6px;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.3;
}

.uppy-Dashboard-Item-errorMessageBtn {
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
}

.uppy-Dashboard-Item-preview .uppy-Dashboard-Item-errorMessage {
  display: none;
}

.uppy-size--md .uppy-Dashboard-Item-preview .uppy-Dashboard-Item-errorMessage {
  border-top: 1px solid #f7c2c8;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 6px 8px;
  line-height: 1.4;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.uppy-Dashboard-Item-fileInfo .uppy-Dashboard-Item-errorMessage {
  border: 1px solid #f7c2c8;
  border-radius: 3px;
  display: inline-block;
  position: static;
}

.uppy-size--md .uppy-Dashboard-Item-fileInfo .uppy-Dashboard-Item-errorMessage {
  display: none;
}

.uppy-Dashboard-Item-action {
  color: #939393;
  cursor: pointer;
}

.uppy-Dashboard-Item-action:focus {
  outline: none;
}

.uppy-Dashboard-Item-action::-moz-focus-inner {
  border: 0;
}

.uppy-Dashboard-Item-action:focus {
  box-shadow: 0 0 0 3px #2275d780;
}

.uppy-Dashboard-Item-action:hover {
  color: #1f1f1f;
  opacity: 1;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-action {
  color: #cfcfcf;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-action:focus {
  outline: none;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-action::-moz-focus-inner {
  border: 0;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-action:focus {
  box-shadow: 0 0 0 2px #aae1ffd9;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-action:hover {
  color: #eaeaea;
}

.uppy-Dashboard-Item-action--remove {
  color: #1f1f1f;
  opacity: .95;
}

.uppy-Dashboard-Item-action--remove:hover {
  color: #000;
  opacity: 1;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-action--remove {
  color: #525252;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item-action--remove:hover {
  color: #333;
}

.uppy-Dashboard:not(.uppy-size--md) .uppy-Dashboard-Item-actionWrapper {
  align-items: center;
  display: flex;
}

.uppy-Dashboard:not(.uppy-size--md) .uppy-Dashboard-Item-action {
  width: 22px;
  height: 22px;
  margin-left: 3px;
  padding: 3px;
}

.uppy-Dashboard:not(.uppy-size--md) .uppy-Dashboard-Item-action:focus {
  border-radius: 3px;
}

.uppy-size--md .uppy-Dashboard-Item-action--copyLink, .uppy-size--md .uppy-Dashboard-Item-action--edit {
  width: 16px;
  height: 16px;
  padding: 0;
}

.uppy-size--md .uppy-Dashboard-Item-action--copyLink:focus, .uppy-size--md .uppy-Dashboard-Item-action--edit:focus {
  border-radius: 3px;
}

[dir="ltr"] .uppy-size--md .uppy-Dashboard-Item-action--remove {
  right: -8px;
}

[dir="rtl"] .uppy-size--md .uppy-Dashboard-Item-action--remove {
  left: -8px;
}

.uppy-size--md .uppy-Dashboard-Item-action--remove {
  z-index: 1002;
  width: 18px;
  height: 18px;
  padding: 0;
  position: absolute;
  top: -8px;
}

.uppy-size--md .uppy-Dashboard-Item-action--remove:focus {
  border-radius: 50%;
}

[dir="ltr"] .uppy-Dashboard-Item {
  padding-right: 0;
}

[dir="rtl"] .uppy-Dashboard-Item {
  padding-left: 0;
}

.uppy-Dashboard-Item {
  border-bottom: 1px solid #eaeaea;
  align-items: center;
  padding: 10px;
  display: flex;
}

[data-uppy-theme="dark"] .uppy-Dashboard-Item {
  border-bottom: 1px solid #333;
}

[dir="ltr"] .uppy-size--md .uppy-Dashboard-Item {
  float: left;
}

[dir="rtl"] .uppy-size--md .uppy-Dashboard-Item {
  float: right;
}

.uppy-size--md .uppy-Dashboard-Item {
  width: calc(33.333% - 30px);
  height: 215px;
  border-bottom: 0;
  margin: 5px 15px;
  padding: 0;
  display: block;
  position: relative;
}

.uppy-size--lg .uppy-Dashboard-Item {
  width: calc(25% - 30px);
  height: 190px;
  margin: 5px 15px;
}

.uppy-size--xl .uppy-Dashboard-Item {
  width: calc(20% - 30px);
  height: 210px;
}

.uppy-Dashboard-Item.is-ghost .uppy-Dashboard-Item-previewInnerWrap {
  opacity: .2;
}

.uppy-Dashboard-Item.is-ghost .uppy-Dashboard-Item-name {
  opacity: .7;
}

.uppy-Dashboard-Item.is-ghost .uppy-Dashboard-Item-preview:before {
  z-index: 1005;
  opacity: .5;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='39' viewBox='0 0 35 39'%3E%3Cpath d='M1.708 38.66c1.709 0 3.417-3.417 6.834-3.417 3.416 0 5.125 3.417 8.61 3.417 3.348 0 5.056-3.417 8.473-3.417 4.305 0 5.125 3.417 6.833 3.417.889 0 1.709-.889 1.709-1.709v-19.68C34.167-5.757 0-5.757 0 17.271v19.68c0 .82.888 1.709 1.708 1.709zm8.542-17.084a3.383 3.383 0 01-3.417-3.416 3.383 3.383 0 013.417-3.417 3.383 3.383 0 013.417 3.417 3.383 3.383 0 01-3.417 3.416zm13.667 0A3.383 3.383 0 0120.5 18.16a3.383 3.383 0 013.417-3.417 3.383 3.383 0 013.416 3.417 3.383 3.383 0 01-3.416 3.416z' fill='%2523000' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-position: 50% 10px;
  background-repeat: no-repeat;
  background-size: 25px;
  position: absolute;
  inset: 0;
}

.uppy-size--md .uppy-Dashboard-Item.is-ghost .uppy-Dashboard-Item-preview:before {
  background-position: 50%;
  background-size: 40px;
}

.uppy-Dashboard-Item-preview {
  position: relative;
}

.uppy-Dashboard:not(.uppy-size--md) .uppy-Dashboard-Item-preview {
  width: 50px;
  height: 50px;
  flex-grow: 0;
  flex-shrink: 0;
}

.uppy-size--md .uppy-Dashboard-Item-preview {
  width: 100%;
  height: 140px;
}

.uppy-size--lg .uppy-Dashboard-Item-preview {
  height: 120px;
}

.uppy-size--xl .uppy-Dashboard-Item-preview {
  height: 140px;
}

[dir="ltr"] .uppy-Dashboard-Item-fileInfoAndButtons {
  padding-right: 8px;
}

[dir="rtl"] .uppy-Dashboard-Item-fileInfoAndButtons {
  padding-left: 8px;
}

[dir="ltr"] .uppy-Dashboard-Item-fileInfoAndButtons {
  padding-left: 12px;
}

[dir="rtl"] .uppy-Dashboard-Item-fileInfoAndButtons {
  padding-right: 12px;
}

.uppy-Dashboard-Item-fileInfoAndButtons {
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.uppy-size--md .uppy-Dashboard-Item-fileInfoAndButtons {
  width: 100%;
  align-items: flex-start;
  padding: 9px 0 0;
}

.uppy-Dashboard-Item-fileInfo {
  flex-grow: 1;
  flex-shrink: 1;
}

.uppy-Dashboard-Item-actionWrapper {
  flex-grow: 0;
  flex-shrink: 0;
}

.uppy-Dashboard-Item.is-inprogress .uppy-Dashboard-Item-previewInnerWrap:after, .uppy-Dashboard-Item.is-error .uppy-Dashboard-Item-previewInnerWrap:after {
  display: block;
}

.uppy-Dashboard-Item.is-inprogress:not(.is-resumable) .uppy-Dashboard-Item-action--remove {
  display: none;
}

[dir="ltr"] .uppy-Dashboard-Item-errorDetails {
  left: 2px;
}

[dir="rtl"] .uppy-Dashboard-Item-errorDetails {
  right: 2px;
}

.uppy-Dashboard-Item-errorDetails {
  width: 13px;
  height: 13px;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: help;
  appearance: none;
  background-color: #939393;
  border: none;
  border-radius: 50%;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  display: inline-block;
  position: relative;
  top: 0;
}

.uppy-Dashboard-Item-errorDetails:after {
  word-wrap: break-word;
  line-height: 1.3;
}

.uppy-Dashboard-FileCard {
  z-index: 1005;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  flex-direction: column;
  display: flex;
  position: absolute;
  inset: 0;
  box-shadow: 0 0 10px 4px #0000001a;
}

.uppy-Dashboard-FileCard .uppy-DashboardContent-bar {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.uppy-Dashboard-FileCard .uppy-Dashboard-FileCard-actions {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.uppy-Dashboard-FileCard-inner {
  height: 100%;
  min-height: 0;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
}

.uppy-Dashboard-FileCard-preview {
  height: 60%;
  min-height: 0;
  border-bottom: 1px solid #eaeaea;
  flex-grow: 0;
  flex-shrink: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

[data-uppy-theme="dark"] .uppy-Dashboard-FileCard-preview {
  background-color: #333;
  border-bottom: 0;
}

.uppy-Dashboard-FileCard-preview img.uppy-Dashboard-Item-previewImg {
  max-width: 90%;
  max-height: 90%;
  object-fit: cover;
  border-radius: 3px;
  flex: none;
  box-shadow: 0 3px 20px #00000026;
}

[dir="ltr"] .uppy-Dashboard-FileCard-edit {
  right: 10px;
}

[dir="rtl"] .uppy-Dashboard-FileCard-edit {
  left: 10px;
}

.uppy-Dashboard-FileCard-edit {
  color: #fff;
  background-color: #00000080;
  border-radius: 50px;
  padding: 7px 15px;
  font-size: 13px;
  position: absolute;
  top: 10px;
}

.uppy-Dashboard-FileCard-edit:focus {
  outline: none;
}

.uppy-Dashboard-FileCard-edit::-moz-focus-inner {
  border: 0;
}

.uppy-Dashboard-FileCard-edit:focus {
  box-shadow: 0 0 0 3px #2275d780;
}

.uppy-Dashboard-FileCard-edit:hover {
  background-color: #000c;
}

.uppy-Dashboard-FileCard-info {
  height: 40%;
  -webkit-overflow-scrolling: touch;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 30px 20px 20px;
  overflow-y: auto;
}

[data-uppy-theme="dark"] .uppy-Dashboard-FileCard-info {
  background-color: #1f1f1f;
}

.uppy-Dashboard-FileCard-fieldset {
  max-width: 640px;
  border: 0;
  margin: auto auto 12px;
  padding: 0;
  font-size: 0;
}

.uppy-Dashboard-FileCard-label {
  width: 22%;
  color: #525252;
  vertical-align: middle;
  font-size: 12px;
  display: inline-block;
}

.uppy-size--md .uppy-Dashboard-FileCard-label {
  font-size: 14px;
}

[data-uppy-theme="dark"] .uppy-Dashboard-FileCard-label {
  color: #eaeaea;
}

.uppy-Dashboard-FileCard-input {
  width: 78%;
  vertical-align: middle;
  display: inline-block;
}

.uppy-Dashboard-FileCard-actions {
  height: 55px;
  background-color: #fafafa;
  border-top: 1px solid #eaeaea;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  padding: 0 15px;
  display: flex;
}

.uppy-size--md .uppy-Dashboard-FileCard-actions {
  height: 65px;
}

[data-uppy-theme="dark"] .uppy-Dashboard-FileCard-actions {
  background-color: #1f1f1f;
  border-top: 1px solid #333;
}

[dir="ltr"] .uppy-Dashboard-FileCard-actionsBtn {
  margin-right: 10px;
}

[dir="rtl"] .uppy-Dashboard-FileCard-actionsBtn {
  margin-left: 10px;
}

.uppy-transition-slideDownUp-enter {
  opacity: .01;
  transition: transform .25s ease-in-out, opacity .25s ease-in-out;
  transform: translate3d(0, -105%, 0);
}

.uppy-transition-slideDownUp-enter.uppy-transition-slideDownUp-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.uppy-transition-slideDownUp-leave {
  opacity: 1;
  transition: transform .25s ease-in-out, opacity .25s ease-in-out;
  transform: translate3d(0, 0, 0);
}

.uppy-transition-slideDownUp-leave.uppy-transition-slideDownUp-leave-active {
  opacity: .01;
  transform: translate3d(0, -105%, 0);
}

@keyframes uppy-Dashboard-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes uppy-Dashboard-fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes uppy-Dashboard-slideDownAndFadeIn {
  from {
    opacity: 0;
    transform: translate3d(-50%, -70%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0);
  }
}

@keyframes uppy-Dashboard-slideDownAndFadeIn--small {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes uppy-Dashboard-slideUpFadeOut {
  from {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-50%, -70%, 0);
  }
}

@keyframes uppy-Dashboard-slideUpFadeOut--small {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
}

.uppy-Dashboard--modal {
  z-index: 1001;
}

.uppy-Dashboard--modal[aria-hidden="true"] {
  display: none;
}

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose > .uppy-Dashboard-inner {
  animation: uppy-Dashboard-slideDownAndFadeIn--small .3s cubic-bezier(0, 0, .2, 1);
}

@media only screen and (min-width: 820px) {
  .uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose > .uppy-Dashboard-inner {
    animation: uppy-Dashboard-slideDownAndFadeIn .3s cubic-bezier(0, 0, .2, 1);
  }
}

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose > .uppy-Dashboard-overlay {
  animation: uppy-Dashboard-fadeIn .3s cubic-bezier(0, 0, .2, 1);
}

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose.uppy-Dashboard--isClosing > .uppy-Dashboard-inner {
  animation: uppy-Dashboard-slideUpFadeOut--small .3s cubic-bezier(0, 0, .2, 1);
}

@media only screen and (min-width: 820px) {
  .uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose.uppy-Dashboard--isClosing > .uppy-Dashboard-inner {
    animation: uppy-Dashboard-slideUpFadeOut .3s cubic-bezier(0, 0, .2, 1);
  }
}

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose.uppy-Dashboard--isClosing > .uppy-Dashboard-overlay {
  animation: uppy-Dashboard-fadeOut .3s cubic-bezier(0, 0, .2, 1);
}

.uppy-Dashboard-isFixed {
  height: 100vh;
  overflow: hidden;
}

.uppy-Dashboard--modal .uppy-Dashboard-overlay {
  z-index: 1001;
  background-color: #00000080;
  position: fixed;
  inset: 0;
}

.uppy-Dashboard-inner {
  max-width: 100%;
  max-height: 100%;
  background-color: #fafafa;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  outline: none;
  position: relative;
}

.uppy-size--md .uppy-Dashboard-inner {
  min-height: auto;
}

@media only screen and (min-width: 820px) {
  .uppy-Dashboard-inner {
    width: 750px;
    height: 550px;
  }
}

.uppy-Dashboard--modal .uppy-Dashboard-inner {
  z-index: 1002;
}

[data-uppy-theme="dark"] .uppy-Dashboard-inner {
  background-color: #1f1f1f;
}

.uppy-Dashboard--isDisabled .uppy-Dashboard-inner {
  cursor: not-allowed;
}

.uppy-Dashboard-innerWrap {
  height: 100%;
  opacity: 0;
  border-radius: 5px;
  flex-direction: column;
  display: flex;
  position: relative;
  overflow: hidden;
}

.uppy-Dashboard--isInnerWrapVisible .uppy-Dashboard-innerWrap {
  opacity: 1;
}

.uppy-Dashboard--isDisabled .uppy-Dashboard-innerWrap {
  opacity: .6;
  filter: grayscale();
  user-select: none;
  pointer-events: none;
}

.uppy-Dashboard--isDisabled .uppy-ProviderIconBg {
  fill: #9f9f9f;
}

.uppy-Dashboard--modal .uppy-Dashboard-inner {
  border: none;
  position: fixed;
  inset: 35px 15px 15px;
}

@media only screen and (min-width: 820px) {
  .uppy-Dashboard--modal .uppy-Dashboard-inner {
    top: 50%;
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%);
    box-shadow: 0 5px 15px 4px #00000026;
  }
}

[dir="ltr"] .uppy-Dashboard-close {
  right: -2px;
}

[dir="rtl"] .uppy-Dashboard-close {
  left: -2px;
}

.uppy-Dashboard-close {
  z-index: 1005;
  color: #ffffffe6;
  cursor: pointer;
  font-size: 27px;
  display: block;
  position: absolute;
  top: -33px;
}

.uppy-Dashboard-close:focus {
  outline: none;
}

.uppy-Dashboard-close::-moz-focus-inner {
  border: 0;
}

.uppy-Dashboard-close:focus {
  color: #8cb8ed;
}

@media only screen and (min-width: 820px) {
  [dir="ltr"] .uppy-Dashboard-close {
    right: -35px;
  }

  [dir="rtl"] .uppy-Dashboard-close {
    left: -35px;
  }

  .uppy-Dashboard-close {
    font-size: 35px;
    top: -10px;
  }
}

.uppy-Dashboard-serviceMsg {
  z-index: 1004;
  background-color: #fffbf7;
  border-top: 1px solid #edd4b9;
  border-bottom: 1px solid #edd4b9;
  padding: 12px 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
  position: relative;
  top: -1px;
}

.uppy-size--md .uppy-Dashboard-serviceMsg {
  font-size: 14px;
  line-height: 1.4;
}

[data-uppy-theme="dark"] .uppy-Dashboard-serviceMsg {
  color: #eaeaea;
  background-color: #1f1f1f;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
}

.uppy-Dashboard-serviceMsg-title {
  margin-bottom: 4px;
  padding-left: 42px;
  line-height: 1;
  display: block;
}

.uppy-Dashboard-serviceMsg-text {
  padding: 0 15px;
}

.uppy-Dashboard-serviceMsg-actionBtn {
  color: #2275d7;
  font-weight: inherit;
  font-size: inherit;
  vertical-align: initial;
}

[data-uppy-theme="dark"] .uppy-Dashboard-serviceMsg-actionBtn {
  color: #02baf2e6;
}

.uppy-Dashboard-serviceMsg-icon {
  position: absolute;
  top: 10px;
  left: 15px;
}

.uppy-Dashboard-AddFiles {
  height: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

[data-uppy-drag-drop-supported="true"] .uppy-Dashboard-AddFiles {
  height: calc(100% - 14px);
  border: 1px dashed #dfdfdf;
  border-radius: 3px;
  margin: 7px;
}

.uppy-Dashboard-AddFilesPanel .uppy-Dashboard-AddFiles {
  height: calc(100% - 54px);
  border: none;
}

.uppy-Dashboard--modal .uppy-Dashboard-AddFiles {
  border-color: #cfcfcf;
}

[data-uppy-theme="dark"] .uppy-Dashboard-AddFiles {
  border-color: #757575;
}

.uppy-Dashboard-AddFiles-info {
  margin-top: auto;
  padding-top: 15px;
  padding-bottom: 15px;
  display: none;
}

.uppy-size--height-md .uppy-Dashboard-AddFiles-info {
  display: block;
}

.uppy-size--md .uppy-Dashboard-AddFiles-info {
  padding-top: 30px;
  padding-bottom: 0;
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
}

[data-uppy-num-acquirers="0"] .uppy-Dashboard-AddFiles-info {
  margin-top: 0;
}

.uppy-Dashboard-browse {
  color: #2275d7e6;
  cursor: pointer;
}

.uppy-Dashboard-browse:focus {
  outline: none;
}

.uppy-Dashboard-browse::-moz-focus-inner {
  border: 0;
}

.uppy-Dashboard-browse:hover, .uppy-Dashboard-browse:focus {
  border-bottom: 1px solid #2275d7;
}

[data-uppy-theme="dark"] .uppy-Dashboard-browse {
  color: #02baf2e6;
}

[data-uppy-theme="dark"] .uppy-Dashboard-browse:hover, [data-uppy-theme="dark"] .uppy-Dashboard-browse:focus {
  border-bottom: 1px solid #02baf2;
}

.uppy-Dashboard-browseBtn {
  width: 100%;
  margin-top: 8px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  display: block;
}

.uppy-size--md .uppy-Dashboard-browseBtn {
  width: auto;
  margin: 15px auto;
  padding: 13px 44px;
  font-size: 15px;
}

.uppy-Dashboard-AddFiles-list {
  width: 100%;
  -webkit-overflow-scrolling: touch;
  flex-direction: column;
  flex: 1;
  margin-top: 2px;
  padding: 2px 0;
  display: flex;
  overflow-y: auto;
}

.uppy-size--md .uppy-Dashboard-AddFiles-list {
  max-width: 600px;
  flex-flow: wrap;
  flex: none;
  justify-content: center;
  margin-top: 15px;
  padding-top: 0;
  overflow-y: visible;
}

.uppy-DashboardTab {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #eaeaea;
}

[data-uppy-theme="dark"] .uppy-DashboardTab {
  border-bottom: 1px solid #333;
}

.uppy-size--md .uppy-DashboardTab {
  width: initial;
  border-bottom: none;
  margin-bottom: 10px;
  display: inline-block;
}

.uppy-DashboardTab-btn {
  width: 100%;
  height: 100%;
  color: #525252;
  cursor: pointer;
  appearance: none;
  background-color: #0000;
  flex-direction: row;
  align-items: center;
  padding: 12px 15px;
  display: flex;
}

.uppy-DashboardTab-btn:focus {
  outline: none;
}

.uppy-DashboardTab-btn::-moz-focus-inner {
  border: 0;
}

[dir="ltr"] .uppy-size--md .uppy-DashboardTab-btn {
  margin-right: 1px;
}

[dir="rtl"] .uppy-size--md .uppy-DashboardTab-btn {
  margin-left: 1px;
}

.uppy-size--md .uppy-DashboardTab-btn {
  width: 86px;
  border-radius: 5px;
  flex-direction: column;
  padding: 10px 3px;
}

[data-uppy-theme="dark"] .uppy-DashboardTab-btn {
  color: #eaeaea;
}

.uppy-DashboardTab-btn::-moz-focus-inner {
  border: 0;
}

.uppy-DashboardTab-btn:hover {
  background-color: #f1f3f6;
}

[data-uppy-theme="dark"] .uppy-DashboardTab-btn:hover {
  background-color: #333;
}

.uppy-DashboardTab-btn:active, .uppy-DashboardTab-btn:focus {
  background-color: #eceef2;
}

[data-uppy-theme="dark"] .uppy-DashboardTab-btn:active, [data-uppy-theme="dark"] .uppy-DashboardTab-btn:focus {
  background-color: #525252;
}

[dir="ltr"] .uppy-DashboardTab-btn svg {
  margin-right: 10px;
}

[dir="rtl"] .uppy-DashboardTab-btn svg {
  margin-left: 10px;
}

.uppy-DashboardTab-btn svg {
  max-width: 100%;
  max-height: 100%;
  vertical-align: text-top;
  transition: transform .15s ease-in-out;
  display: inline-block;
  overflow: hidden;
}

[dir="ltr"] .uppy-size--md .uppy-DashboardTab-btn svg {
  margin-right: 0;
}

[dir="rtl"] .uppy-size--md .uppy-DashboardTab-btn svg {
  margin-left: 0;
}

.uppy-DashboardTab-name {
  font-size: 14px;
  font-weight: 500;
}

.uppy-size--md .uppy-DashboardTab-name {
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 11px;
  line-height: 15px;
}

.uppy-DashboardTab svg {
  width: 23px;
  height: 23px;
  vertical-align: middle;
}

.uppy-size--md .uppy-DashboardTab svg {
  width: 30px;
  height: 30px;
}

.uppy-Dashboard-input {
  z-index: -1;
  width: .1px;
  height: .1px;
  opacity: 0;
  position: absolute;
  overflow: hidden;
}

.uppy-DashboardContent-bar {
  z-index: 1004;
  width: 100%;
  height: 40px;
  background-color: #fafafa;
  border-bottom: 1px solid #eaeaea;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.uppy-size--md .uppy-DashboardContent-bar {
  height: 50px;
  padding: 0 15px;
}

[data-uppy-theme="dark"] .uppy-DashboardContent-bar {
  background-color: #1f1f1f;
  border-bottom: 1px solid #333;
}

.uppy-DashboardContent-title {
  width: 100%;
  max-width: 170px;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
  margin: auto;
  font-size: 12px;
  font-weight: 500;
  line-height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
}

.uppy-size--md .uppy-DashboardContent-title {
  max-width: 300px;
  font-size: 14px;
  line-height: 50px;
}

[data-uppy-theme="dark"] .uppy-DashboardContent-title {
  color: #eaeaea;
}

[dir="ltr"] .uppy-DashboardContent-back, [dir="ltr"] .uppy-DashboardContent-save {
  margin-left: -6px;
}

[dir="rtl"] .uppy-DashboardContent-back, [dir="rtl"] .uppy-DashboardContent-save {
  margin-right: -6px;
}

.uppy-DashboardContent-back, .uppy-DashboardContent-save {
  color: inherit;
  font-size: inherit;
  -webkit-appearance: none;
  color: #2275d7;
  cursor: pointer;
  background: none;
  border: 0;
  border-radius: 3px;
  margin: 0;
  padding: 7px 6px;
  font-family: inherit;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
}

.uppy-DashboardContent-back:focus, .uppy-DashboardContent-save:focus {
  outline: none;
}

.uppy-DashboardContent-back::-moz-focus-inner, .uppy-DashboardContent-save::-moz-focus-inner {
  border: 0;
}

.uppy-DashboardContent-back:hover, .uppy-DashboardContent-save:hover {
  color: #1b5dab;
}

.uppy-DashboardContent-back:focus, .uppy-DashboardContent-save:focus {
  background-color: #eceef2;
}

[data-uppy-theme="dark"] .uppy-DashboardContent-back:focus, [data-uppy-theme="dark"] .uppy-DashboardContent-save:focus {
  background-color: #333;
}

.uppy-size--md .uppy-DashboardContent-back, .uppy-size--md .uppy-DashboardContent-save {
  font-size: 14px;
}

[data-uppy-theme="dark"] .uppy-DashboardContent-back, [data-uppy-theme="dark"] .uppy-DashboardContent-save {
  color: #02baf2;
}

[dir="ltr"] .uppy-DashboardContent-addMore {
  margin-right: -5px;
}

[dir="rtl"] .uppy-DashboardContent-addMore {
  margin-left: -5px;
}

.uppy-DashboardContent-addMore {
  color: inherit;
  font-size: inherit;
  -webkit-appearance: none;
  width: 29px;
  height: 29px;
  color: #2275d7;
  cursor: pointer;
  background: none;
  border: 0;
  border-radius: 3px;
  margin: 0;
  padding: 7px 8px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1;
}

.uppy-DashboardContent-addMore:focus {
  outline: none;
}

.uppy-DashboardContent-addMore::-moz-focus-inner {
  border: 0;
}

.uppy-DashboardContent-addMore:hover {
  color: #1b5dab;
}

.uppy-DashboardContent-addMore:focus {
  background-color: #eceef2;
}

[data-uppy-theme="dark"] .uppy-DashboardContent-addMore:focus {
  background-color: #333;
}

[dir="ltr"] .uppy-size--md .uppy-DashboardContent-addMore {
  margin-right: -8px;
}

[dir="rtl"] .uppy-size--md .uppy-DashboardContent-addMore {
  margin-left: -8px;
}

.uppy-size--md .uppy-DashboardContent-addMore {
  width: auto;
  height: auto;
  font-size: 14px;
}

[data-uppy-theme="dark"] .uppy-DashboardContent-addMore {
  color: #02baf2;
}

[dir="ltr"] .uppy-DashboardContent-addMore svg {
  margin-right: 4px;
}

[dir="rtl"] .uppy-DashboardContent-addMore svg {
  margin-left: 4px;
}

.uppy-DashboardContent-addMore svg {
  vertical-align: baseline;
}

.uppy-size--md .uppy-DashboardContent-addMore svg {
  width: 11px;
  height: 11px;
}

.uppy-DashboardContent-addMoreCaption {
  display: none;
}

.uppy-size--md .uppy-DashboardContent-addMoreCaption {
  display: inline;
}

.uppy-DashboardContent-panel {
  z-index: 1005;
  background-color: #f5f5f5;
  border-radius: 5px;
  flex-direction: column;
  flex: 1;
  display: flex;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.uppy-Dashboard-AddFilesPanel {
  z-index: 1005;
  background: linear-gradient(0deg, #fafafa 35%, #fafafad9 100%);
  border-radius: 5px;
  flex-direction: column;
  display: flex;
  position: absolute;
  inset: 0;
  overflow: hidden;
  box-shadow: 0 0 10px 5px #00000026;
}

[data-uppy-theme="dark"] .uppy-Dashboard-AddFilesPanel {
  background-color: #333;
  background-image: linear-gradient(0deg, #1f1f1f 35%, #1f1f1fd9 100%);
}

.uppy-Dashboard--isAddFilesPanelVisible .uppy-Dashboard-files {
  filter: blur(2px);
}

.uppy-Dashboard-progress {
  width: 100%;
  height: 12%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.uppy-Dashboard-progressBarContainer.is-active {
  z-index: 1004;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.uppy-Dashboard-filesContainer {
  flex: 1;
  margin: 0;
  position: relative;
  overflow-y: hidden;
}

.uppy-Dashboard-filesContainer:after {
  clear: both;
  content: "";
  display: table;
}

.uppy-Dashboard-files {
  -webkit-overflow-scrolling: touch;
  flex: 1;
  margin: 0;
  padding: 0 0 10px;
  overflow-y: auto;
}

.uppy-size--md .uppy-Dashboard-files {
  padding-top: 10px;
}

.uppy-Dashboard-dropFilesHereHint {
  z-index: 2000;
  color: #757575;
  text-align: center;
  visibility: hidden;
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 1v1C11.85 2 2 11.85 2 24s9.85 22 22 22 22-9.85 22-22S36.15 2 24 2V1zm0 0V0c13.254 0 24 10.746 24 24S37.254 48 24 48 0 37.254 0 24 10.746 0 24 0v1zm7.707 19.293a.999.999 0 1 1-1.414 1.414L25 16.414V34a1 1 0 1 1-2 0V16.414l-5.293 5.293a.999.999 0 1 1-1.414-1.414l7-7a.999.999 0 0 1 1.414 0l7 7z' fill='%232275D7' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-position: 50%;
  background-repeat: no-repeat;
  border: 1px dashed #2275d7;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  padding-top: 90px;
  font-size: 16px;
  display: flex;
  position: absolute;
  inset: 7px;
}

[data-uppy-theme="dark"] .uppy-Dashboard-dropFilesHereHint {
  color: #bbb;
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 1v1C11.85 2 2 11.85 2 24s9.85 22 22 22 22-9.85 22-22S36.15 2 24 2V1zm0 0V0c13.254 0 24 10.746 24 24S37.254 48 24 48 0 37.254 0 24 10.746 0 24 0v1zm7.707 19.293a.999.999 0 1 1-1.414 1.414L25 16.414V34a1 1 0 1 1-2 0V16.414l-5.293 5.293a.999.999 0 1 1-1.414-1.414l7-7a.999.999 0 0 1 1.414 0l7 7z' fill='%2302BAF2' fill-rule='nonzero'/%3E%3C/svg%3E");
  border-color: #02baf2;
}

.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-dropFilesHereHint {
  visibility: visible;
}

.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-DashboardContent-bar, .uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-files, .uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-progressindicators, .uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-serviceMsg {
  opacity: .15;
}

.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-AddFiles {
  opacity: .03;
}

.uppy-Dashboard-AddFiles-title {
  width: 100%;
  color: #000;
  text-align: inline-start;
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 0 15px;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.35;
}

.uppy-size--md .uppy-Dashboard-AddFiles-title {
  max-width: 480px;
  text-align: center;
  margin-top: 5px;
  font-size: 25px;
  font-weight: 400;
}

[data-uppy-num-acquirers="0"] .uppy-Dashboard-AddFiles-title {
  text-align: center;
}

[data-uppy-theme="dark"] .uppy-Dashboard-AddFiles-title {
  color: #eaeaea;
}

.uppy-Dashboard-AddFiles-title button {
  font-weight: 500;
}

.uppy-size--md .uppy-Dashboard-AddFiles-title button {
  font-weight: 400;
}

.uppy-Dashboard-note {
  max-width: 350px;
  color: #757575;
  text-align: center;
  margin: auto;
  padding: 0 15px;
  font-size: 14px;
  line-height: 1.25;
}

.uppy-size--md .uppy-Dashboard-note {
  max-width: 600px;
  font-size: 15px;
  line-height: 1.35;
}

[data-uppy-theme="dark"] .uppy-Dashboard-note {
  color: #cfcfcf;
}

a.uppy-Dashboard-poweredBy {
  color: #939393;
  text-align: center;
  margin-top: 8px;
  font-size: 11px;
  text-decoration: none;
  display: inline-block;
}

.uppy-Dashboard-poweredByIcon {
  vertical-align: text-top;
  opacity: .9;
  fill: none;
  stroke: #939393;
  margin-left: 1px;
  margin-right: 1px;
  position: relative;
  top: 1px;
}

.uppy-Dashboard-Item-previewIcon {
  z-index: 100;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.uppy-size--md .uppy-Dashboard-Item-previewIcon {
  width: 38px;
  height: 38px;
}

.uppy-Dashboard-Item-previewIcon svg {
  width: 100%;
  height: 100%;
}

.uppy-Dashboard-Item-previewIconWrap {
  height: 76px;
  max-height: 75%;
  position: relative;
}

.uppy-Dashboard-Item-previewIconBg {
  width: 100%;
  height: 100%;
  filter: drop-shadow(0 1px 1px #0000001a);
}

.uppy-Dashboard-upload {
  width: 50px;
  height: 50px;
  position: relative;
}

.uppy-size--md .uppy-Dashboard-upload {
  width: 60px;
  height: 60px;
}

.uppy-Dashboard-upload .uppy-c-icon {
  width: 50%;
  position: relative;
  top: 1px;
}

[dir="ltr"] .uppy-Dashboard-uploadCount {
  right: -12px;
}

[dir="rtl"] .uppy-Dashboard-uploadCount {
  left: -12px;
}

.uppy-Dashboard-uploadCount {
  width: 16px;
  height: 16px;
  color: #fff;
  background-color: #1bb240;
  border-radius: 50%;
  font-size: 8px;
  line-height: 16px;
  position: absolute;
  top: -12px;
}

.uppy-size--md .uppy-Dashboard-uploadCount {
  width: 18px;
  height: 18px;
  font-size: 9px;
  line-height: 18px;
}

.fp-sandbox {
  color: #333b4f;
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-family: Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.4;
}

.fp-sandbox *, .fp-sandbox :after, .fp-sandbox :before {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: #0000;
  -webkit-tap-highlight-color: transparent;
  box-sizing: inherit;
  outline: none;
}

.fp-sandbox .btn {
  color: #0280ff;
  text-align: center;
  background-color: #0000;
  border: 1px solid #0280ff;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
}

.fp-sandbox .btn:hover, .fp-sandbox .btn.btn--active, .fp-sandbox .btn.btn--icon-only:hover {
  color: #fcfcfc;
  background-color: #0280ff;
}

.fp-sandbox .btn.btn--icon-only {
  min-width: 46px;
  background-color: #fcfcfc;
}

.fp-sandbox .btn.btn--icon-only .icon {
  margin-left: 0;
}

.fp-sandbox .btn > span {
  justify-content: center;
  align-items: center;
  display: flex;
}

.fp-sandbox .btn .icon {
  margin-left: 5px;
}

.fp-sandbox .btn .icon path {
  fill: #0280ff;
}

.fp-sandbox .btn:hover .icon path {
  fill: #fcfcfc;
}

.fp-sandbox .select {
  cursor: pointer;
  height: 40px;
  min-width: 80px;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
}

.fp-sandbox .select:hover {
  border-color: #0280ff;
}

.fp-sandbox .sandbox {
  width: 100%;
  min-width: 850px;
  border: 1px solid #e5e5e5;
  justify-content: space-between;
  align-items: stretch;
  margin: 0 auto;
  display: flex;
}

@media (max-width: 899px) {
  .fp-sandbox .sandbox {
    flex-wrap: wrap !important;
  }

  .fp-sandbox .controls, .fp-sandbox .preview {
    width: 50% !important;
  }

  .fp-sandbox .snippet {
    width: 100% !important;
    border-top: 1px solid #f5f5f5 !important;
  }
}

.fp-sandbox .controls {
  width: 20%;
  min-width: 256px;
  background-color: #f7f7f7;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.fp-sandbox .controls__pages, .fp-sandbox .controls__header, .fp-sandbox .controls__footer, .fp-sandbox .controls__formats, .fp-sandbox .controls__metadata, .fp-sandbox .controls__dimensions {
  width: 100%;
  padding: 10px;
}

.fp-sandbox .controls__footer {
  height: 62px;
  z-index: 10;
  background-color: #fcfcfc;
  border-top: 1px solid #e0e0e0;
}

.fp-sandbox .controls__title {
  margin: 0 0 5px;
  font-size: 14px;
  display: flex;
}

.fp-sandbox .controls__row {
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
  display: flex;
}

.fp-sandbox .controls__row:last-child {
  margin-bottom: 0;
}

.fp-sandbox .controls__row > .controls__label {
  width: 50%;
}

.fp-sandbox .controls__url-input-wrapper {
  background-color: #fcfcfc;
  border: 1px solid #ccc;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  padding: 2px 2px 2px 8px;
  display: flex;
}

.fp-sandbox .controls__url-input {
  width: 100%;
  border: 0;
  flex: 1;
  padding: 0 8px 0 0;
  font-size: 14px;
  font-weight: 400;
  display: flex;
}

.fp-sandbox [data-action="generatePreview"] {
  width: 100%;
}

.fp-sandbox .controls__label {
  cursor: pointer;
  align-items: center;
  font-size: 14px;
  display: flex;
}

.fp-sandbox .controls__radio, .fp-sandbox .controls__checkbox {
  cursor: pointer;
  margin-right: 5px;
}

.fp-sandbox .controls__select {
  cursor: pointer;
  width: 80px;
  height: 35px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 5px;
  font-size: 13px;
  font-weight: 500;
}

.fp-sandbox .controls__textfield {
  width: calc(50% - 10px);
  height: 35px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 8px 9px;
  font-size: 13px;
  font-weight: 400;
}

.fp-sandbox .controls__textfield:focus {
  border-color: #07f;
}

.fp-sandbox .controls__textfield-divider {
  color: #ccc;
  margin: 0 5px;
  font-size: 16px;
  font-weight: 500;
}

.fp-sandbox .controls__textfield-divider:before {
  content: "×";
}

.fp-sandbox [data-value="pages-range"] {
  width: 100%;
  flex: 1;
}

.fp-sandbox .preview {
  width: 40%;
  background-color: #fff;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
  position: relative;
}

.fp-sandbox [data-color="white"] {
  background-color: #fff;
}

.fp-sandbox [data-color="black"] {
  background-color: #000;
}

.fp-sandbox [data-color="transparent"] {
  background-image: linear-gradient(to right, #f0f0f0 1px, #0000 1px), linear-gradient(#f0f0f0 1px, #0000 1px);
  background-position: center;
  background-size: 15px 15px;
}

.fp-sandbox .preview__loader {
  margin: 0 auto;
}

.fp-sandbox .preview__image-wrapper {
  width: 100%;
  height: 400px;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: scroll;
}

.fp-sandbox .preview__image {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  display: block;
}

.fp-sandbox .preview__actions {
  width: calc(100% - 20px);
  z-index: 100;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.fp-sandbox .preview__actions-left, .fp-sandbox .preview__actions-right, .fp-sandbox .preview__actions-thumbnail-actions {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.fp-sandbox .preview__actions-thumbnail-index {
  margin: 0 10px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
}

.fp-sandbox [data-color="black"] .preview__actions-thumbnail-index {
  color: #fff;
}

.fp-sandbox [data-action="setBgBlack"], .fp-sandbox [data-action="setBgWhite"], .fp-sandbox [data-action="viewImageSource"], .fp-sandbox [data-action="setBgTransparent"] {
  width: 40px;
  height: 40px;
  margin-left: 10px;
}

.fp-sandbox [data-action="setBgBlack"], .fp-sandbox [data-action="setBgWhite"], .fp-sandbox [data-action="setBgTransparent"] {
  border: 0;
  border-radius: 4px;
}

.fp-sandbox [data-action="setBgBlack"] {
  background-color: #000;
  border: 1px solid #007cff;
}

.fp-sandbox [data-action="setBgWhite"] {
  background-color: #fff;
  border: 1px solid #007cff;
}

.fp-sandbox [data-action="setBgTransparent"] {
  background-color: #fff;
  background-image: linear-gradient(to right, #f0f0f0 1px, #0000 1px), linear-gradient(#f0f0f0 1px, #0000 1px);
  background-position: center;
  background-size: 8px 8px;
  border: 1px solid #007cff;
}

.fp-sandbox [data-action="viewImageSource"] {
  background-color: #fff;
  min-width: 40px !important;
}

.fp-sandbox pre[class*="language-"], .fp-sandbox code[class*="language-"] {
  color: #5f7d8c;
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  tab-size: 2;
  hyphens: none;
  border-radius: 4px;
  font-family: Courier, monospace;
  font-size: 14px;
  line-height: 18px;
}

.fp-sandbox pre[class*="language-"]::-moz-selection, pre[class*="language-"] ::-moz-selection, .fp-sandbox code[class*="language-"]::-moz-selection, code[class*="language-"] ::-moz-selection {
  background: #e8e8e8;
}

.fp-sandbox pre[class*="language-"]::selection, pre[class*="language-"] ::selection, .fp-sandbox code[class*="language-"]::selection, code[class*="language-"] ::selection {
  background: #eee;
}

.fp-sandbox pre[class*="language-"] {
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  margin: 0 auto;
  padding: 10px;
  overflow: auto;
}

.fp-sandbox :not(pre) > code[class*="language-"] {
  background: #fafafa;
  border: 1px solid #ccc;
  padding: 10px;
}

.fp-sandbox .token.comment, .fp-sandbox .token.prolog, .fp-sandbox .token.doctype, .fp-sandbox .token.cdata {
  color: #b0bec5;
  font-style: italic;
}

.fp-sandbox .token.namespace {
  opacity: .7;
}

.fp-sandbox .token.string, .fp-sandbox .token.attr-value {
  color: #96be64;
}

.fp-sandbox .token.operator {
  color: #82cdc6;
}

.fp-sandbox .token.punctuation {
  color: #64818f;
}

.fp-sandbox .token.entity, .fp-sandbox .token.url, .fp-sandbox .token.symbol, .fp-sandbox .token.number, .fp-sandbox .token.boolean, .fp-sandbox .token.variable, .fp-sandbox .token.constant, .fp-sandbox .token.property, .fp-sandbox .token.regex, .fp-sandbox .token.inserted {
  color: #edb561;
}

.fp-sandbox .token.atrule, .fp-sandbox .token.keyword, .fp-sandbox .token.attr-name, .fp-sandbox .language-autohotkey .token.selector {
  color: #8394c9;
}

.fp-sandbox .token.function, .fp-sandbox .token.deleted, .fp-sandbox .language-autohotkey .token.tag {
  color: #f65848;
}

.fp-sandbox .token.tag, .fp-sandbox .token.selector, .fp-sandbox .language-autohotkey .token.keyword {
  color: #007cff;
}

.fp-sandbox .token.class-name {
  color: #bcb7b7;
}

.fp-sandbox .token.important, .fp-sandbox .token.function, .fp-sandbox .token.bold {
  font-weight: bold;
}

.fp-sandbox .token.italic {
  font-style: italic;
}

.fp-sandbox .snippet {
  width: 40%;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
}

.fp-sandbox .snippet__loader {
  margin: 0 auto;
}

.fp-sandbox .snippet__wrapper {
  width: 100%;
}

.fp-sandbox .snippet__wrapper[data-snippet-type="result"] .snippet__result-wrapper, .fp-sandbox .snippet__wrapper[data-snippet-type="code"] .snippet__samples {
  display: block;
}

.fp-sandbox .snippet__actions {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.fp-sandbox .snippet__actions-left, .fp-sandbox .snippet__actions-right {
  align-items: center;
  display: flex;
}

.fp-sandbox [data-action="showResult"] {
  border-right: 0;
  border-radius: 4px 0 0 4px;
}

.fp-sandbox [data-action="showCode"] {
  border-radius: 0 4px 4px 0;
}

.fp-sandbox [data-action="showCode"], .fp-sandbox [data-action="showResult"] {
  width: 65px;
}

.fp-sandbox .snippet__result-wrapper, .fp-sandbox .snippet__sample-wrapper {
  position: relative;
}

.fp-sandbox .snippet__result, .fp-sandbox .snippet__sample {
  min-height: 384px;
  max-height: 384px;
  position: relative;
}

.fp-sandbox .snippet__samples, .fp-sandbox .snippet__result-wrapper, .fp-sandbox .snippet__sample-wrapper {
  display: none;
}

.fp-sandbox .snippet__result-wrapper, .fp-sandbox .snippet__sample-wrapper {
  padding-bottom: 50px;
}

.fp-sandbox [data-snippet-language="node"] [data-language="js"], .fp-sandbox [data-snippet-language="python"] [data-language="python"], .fp-sandbox [data-snippet-language="ruby"] [data-language="ruby"], .fp-sandbox [data-snippet-language="go"] [data-language="go"], .fp-sandbox [data-snippet-language="elixir"] [data-language="elixir"], .fp-sandbox [data-snippet-language="php"] [data-language="php"] {
  display: block;
}

.fp-sandbox [data-action="copyCode"] {
  background-color: #fafafa;
  position: absolute;
  bottom: 0;
  left: 0;
}



/*# sourceMappingURL=index.css.map */
